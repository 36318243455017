.reports-pagination-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    margin: 0 20px;
}

.reports-pagination-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.reports-pagination-buttons button {
    border: none;
    background: #4c94ff;
    color: #fff;
    border-radius: 4px;
    min-width: 29px;
    padding: 0.25rem 0.5rem;
    margin: 0 4px 10px;
    transition: background-color 0.3s;
}

.reports-pagination-buttons button.active {
    font-weight: bold;
    background-color: #f5f5f0;
    color: #3380e3;
}

.reports-pagination-buttons button:hover {
    background-color: #3380e3;
}

.reports-pagination-buttons button.active:hover {
    background-color: #f5f5f0;
}
