@media (max-width: 768px) {
  .MapReport {
    display: none;
  }

  .slider-report {
    display: none;
  }

  .statistic-block {
    border: 1px solid #4c94ff;
    border-radius: 4px;
  }

  .statistic-wrap {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .stat-item {
    margin: 1vh 0 !important;
    max-width: 100vw !important;
    border: none !important;
  }

  .pics-reports {
    width: 100% !important;
    padding: 0;
  }

  .revers {
    flex-direction: column-reverse;
  }

  .sort-block {
  justify-content: center !important;
  padding: 0;
  }
  
  .selected-date-input {
    width: 50%;

  }
  
  .sort-btn, .reset-btn {
    width: 25%;
  }
}

.slider-report {
  padding: 5vh;
}

.slider-report img {
  height: 30vh;
  width: 40vw;
  object-fit: cover;
  object-position: 0 0;
}

.report-slider-item {
  display: flex;
  width: 70%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  justify-content: center;
  align-items: center;
}

.carousel-control-prev-icon img {
  width: 50px;
  height: 50px;
}

.report-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2vw;
}

.report-info a {
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  width: auto;
  max-width: 200px;
}

.stat-item {
  height: 152px;
  border: 2px solid #4c94ff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.number-stat {
  color: #4c94ff;
  font-weight: 500;
  font-size: 24px;
}

.name-stat {
  font-weight: 400;
  font-size: 16px;
  color: #83868b;
}

.reports-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.reports-all img {
  height: 40vh;
  border-radius: 6px;
}

.report-item-info {
  background-color: #f5f5f0;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vh 3vw;
  height: auto;
  border-radius: 6px;
}

.stat-item {
  margin: 2vh 2vw;
}

.pics-reports {
  display: flex;
  width: 40%;
  overflow-x: hidden;
  border-radius: 4px;
}

.report-item-info a {
  text-decoration: none;
  font-size: 14px;
  max-width: 200px;
}

.MapUkr {
  display: flex;
}

.map-pic {
  flex: 1;
}

.map-info-report {
  flex: 1;
  text-align: left;
  height: 100%;
  min-height: 50vh;
  background-color: #f5f5f0;
  padding: 4vh;
  margin: 0 1vh;
  border-radius: 4px;
}

.map-info-report img {
  height: 100%;
}

.map-info-report a {
  font-size: 14px;
  text-decoration: none;
  text-align: center;
}

.svg-map {
  position: relative;
  z-index: 1;
}

#kyivNameReport {
  position: absolute;
  z-index: 20;
  font-size: 18px;
  fill: white;
  color: white;
  text-shadow: -2px 4px 4px #000000;
}


.date-post-wrap-page img {
  width: 24px;
  height: auto;
}

.date-post-page {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.location {
  display: flex;
  align-items: center;
}


@media (max-width: 768px) {
  .MapHelp {
    display: none !important;
  }
}

.MapUkr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vh 0;
}

.map-pic {
  flex: 1;
  margin: 1vh 1vw;
}

.map-info {
  flex: 1;
  text-align: left;
  height: 100%;
  min-height: 50vh;
  background-color: #f5f5f0;
  padding: 4vh;
  margin: 0 1vh;
  border-radius: 4px;
}

.projects-info {
  height: 50vh;
  overflow-y: auto;
}

.map-info p {
  margin: 0;
}

.map-info h3 {
  text-transform: uppercase;
  margin: 0 0 2vh 0;
}

.map-info h2 {
  margin: 2vh 0;
  text-transform: uppercase;
}

.project-item {
  padding: 2vh 0;
  display: block;
  border-bottom: 1px solid #83868b;
}

.card-project h4 {
  text-transform: uppercase;
}

.project-item:nth-child(1) {
  border-top: 1px solid #83868b;
}

.project-item img {
  width: 200px;
  margin: 1vh 0;
}

.project-info {
  display: flex;
}

.project-info p {
  padding: 1vh 2vw;
}

.project-info img {
  border: 1px solid #83868b;
}

.svg-map {
  stroke: white !important;
}

.ukraine {
  font-size: 36px;
  font-weight: 600;
}

.svg-map {
  position: relative;
  z-index: 1;
}

#kyivName {
  position: absolute;
  z-index: 20;
  font-size: 18px;
  fill: white;
  color: white;
  text-shadow: -2px 4px 4px #000000;
}

.map-ukraine-wrap {
  padding: 40px 0;
}

.map-proj-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-proj-map {
  width: 170px;
  margin: 1vh 2vw;
}

/* Регионы из списка проектов будут иметь цвет ffd600 */
.svg-map path.project-region {
  fill: #ffd600;
}

/* Регионы, которые не в списке, будут иметь цвет 95bfff */
.svg-map path:not(.project-region) {
  fill: #4c94ff;
}

.svg-map path:hover,
.svg-map path:active,
.svg-map path:focus {
  fill: #95bfff !important;
  border: none !important;
  outline: 0 !important;
}

.project-region:active {
  border: none !important;
}

.sort-block {
  display: flex;
  justify-content: flex-end;
  border-radius: 6px;
}

.selected-date-input {
  margin-right: 1rem; /* Отступ между инпутом и кнопками */
  border: 1px solid #83868b; /* Граница для инпута */
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  outline: none;
  font-size: 12px;
}

.sort-btn, .reset-btn {
  background-color: #4c94ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px !important;
}

.sort-btn:hover, .reset-btn:hover {
  background-color: #3380e3; /* Измените цвет при наведении по вашему усмотрению */
}

.reset-btn {
  margin-left: 1rem; /* Отступ между кнопками */
  background-color: transparent;
  border: 1px solid #3380e3;
  color:#3380e3;
}