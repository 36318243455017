@media (max-width: 768px) {
  #contact {
    width: 90vw;
  }
}

#formWasSend {
  min-height: 20vh;
  background-color: #f5f5f0;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%), 0 5px 5px 0 rgb(0 0 0 / 24%);
  border-radius: 6px;
  min-width: 25vw;
}

#closeSent {
  align-self: flex-end;
}
.open-form-send {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.open-form-send h3 {
  margin: 3vh;
}

.sent-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.open-form {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.close-form {
  display: none;
}

.blur-background {
  filter: blur(5px);
}

.form-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  border: none;
  background-color: transparent;
}
.input_item {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.input_item label {
  margin: 5px 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #272727;
}

#contact input,
#contact textarea,
#contact-submit,
#type-help {
  font: 12px "Roboto", Helvetica, Arial, sans-serif;
  border-radius: 4px;
  margin: 0;
}

#contact {
  background: #f5f5f0;
  padding: 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  min-width: 25vw;
}

#contact h3 {
  display: block;
  margin-bottom: 10px;
}

.input_item {
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input,
#contact textarea,
#type-help {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input:hover,
#contact textarea:hover,
#type-help:hover {
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact-submit {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4c94ff;
  color: #fff;
  margin: 5px 0;
  padding: 10px;
  font-size: 15px;
}

#contact-submit:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

#contact input:focus,
#contact textarea:focus,
#type-help:focus {
  outline: 0;
  border: 1px solid #aaa;
}

#message {
  height: 15vh;
}

.iam {
  text-align: center;
}

.iam a {
  color: white;
}

.inputWar {
  border: 1px solid red !important;
}
