@media (max-width: 768px) {
  .projects-cards {
    flex-direction: column !important;
  }

  .projects-slider {
    align-items: center !important;
  }

  .projects-cards a {
    margin: 1vh 1vw;
  }

  .projects-cards button {
    margin: 0 !important;
  }
  .projects-slider {
    flex-direction: column;
  }

  .card-project {
    width: 100% !important;
    margin: 2vh 0;
    max-width: 80% !important;
  }

  .card-project img {
    width: 100% !important;
    height: 20vh;
    object-fit: cover;
    object-position: top;
  }
}

.projects-slider {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1vh 1vw;
}

.projects-cards {
  display: flex;
  height: 100%;
  min-height: 70vh;
  max-width: 100%;
}
.card-project {
  background-color: #f5f5f0;
  border: none;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 20vw;
  border-radius: 4px;
  margin: 2vh 1.5vw;
}

.card-project p,
.card-project h3 {
  padding: 1vh 1vw;
}

.card-project h3 {
  text-transform: uppercase;
  min-height: 7vh;
  font-size: 16px;
}
.card-project p {
  text-align: left;
}

.card-project button {
  margin: 1vh 1vw;
  width: 15vw;
  align-self: center;
}

.card-project img {
  width: 30vw;
  height: 30vh;
  object-fit: cover;
  object-position: top;
}

.card-text {
  flex: 1;
}

.card-project h5 {
  font-weight: 600;
  padding: 2vh;
}

.btn-slider {
  align-self: center;
}
